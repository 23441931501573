<template>
  <v-dialog v-model="dialog" max-width="460" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Documento - {{ user }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Título</v-list-item-title>
                <v-list-item-subtitle>{{ voucher.title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Valor</v-list-item-title>
                <v-list-item-subtitle>R$ {{ voucher && Number(voucher.money).toFixed(2) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Criado em</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(voucher.created_at) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Data inicial</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(voucher.initial_date) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Descrição</v-list-item-title>
                <v-list-item-subtitle>{{ voucher.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Unit</v-list-item-title>
                <v-list-item-subtitle>{{ voucher.unit_id || '-' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Atualizado em</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(voucher.updated_at) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Data final</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(voucher.final_date) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    voucher: Object,
    user: String
  },
  methods: {
    formatDate(date) {
      if (date) return this.$moment(date).format('DD/MM/YYYY, HH:mm:ss')
      return '-'
    }
  }
}
</script>
